.welcome {
  background: #00194a;
  min-height: 520px;
  padding-left: 0px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (min-width: 610px) {
    padding-left: 100px;
  }
  &__content {
    max-width: 580px;
    top: auto;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    position: absolute;
    left: 15px;
    padding-right: 15px;
    z-index: 3;
    bottom: 30px;
    @media (min-width: 960px){
        left: 100px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        top: 50%;
    }
    & .join-us__form{
      padding: 0;
      align-items: flex-start;
    }
    &>.join-us__title{
      text-align: left;
      margin-top: 30px;
      margin-bottom: 0;
    }
    @media (min-width: 960px) {
      left: 100px;
      transform: translateY(-50%);
      top: 50%;
    }
    @media (min-width: 560px) {
      width: 100%;
    }
  }
  &__grafic {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    position: relative;
  }
  // @media(max-width:910px){
  //     padding-left: 30px;
  // }
}

.WelcomeBG {
  height: 50vw;
  min-height: 520px;
  object-fit: cover;
}

.w-Pnalet1 {
  position: absolute;
  top: 10%;
  min-width: 300px;
  width: 32%;
  height: auto;
  right: 18%;
  animation: planet 2.1s ease-in-out alternate-reverse infinite;
  @media (max-width: 1000px) {
    right: 28%;
  }
}
.w-Pnalet2 {
  position: absolute;
  top: 59%;
  min-width: 300px;
  width: 32%;
  height: auto;
  right: -8%;
  animation: planet 1.8s ease-in-out alternate-reverse infinite;
  animation-duration: -1s;
}
.w-Pnalet3 {
  position: absolute;
  top: 41%;
  right: 5%;
  animation: star 2.1s ease-in-out alternate-reverse infinite;
}
.w-Pnalet4 {
  position: absolute;
  top: 68%;
  right: 30%;
  animation: star 1.8s ease-in-out alternate-reverse infinite;
  animation-duration: -1s;
}
.w-Pnalet5 {
  position: absolute;
  bottom: 6%;
  right: 4%;
  animation: star 2.1s ease-in-out alternate-reverse infinite;
}
.w-Pnalet6 {
  position: absolute;
  top: 52%;
  right: 20%;
  animation: star 1.8s ease-in-out alternate-reverse infinite;
  animation-duration: -0.8s;
}
.w-Pnalet7 {
  position: absolute;
  top: 13%;
  right: 26%;
  animation: star 1.4s ease-in-out alternate-reverse infinite;
  animation-duration: -1s;
}
.w-Pnalet8 {
  position: absolute;
  top: 10%;
  right: 8%;
  animation: star 1.2s ease-in-out alternate-reverse infinite;
  animation-duration: -1s;
}

@keyframes planet {
  0% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes star {
  0% {
    transform: scale(0.65);
  }
  100% {
    transform: scale(1.25);
  }
}
