.pdf{
  background-image: url('./../assets/images/roadBG.png');
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  padding-top: 200px;
  padding-bottom: 150px;
  box-sizing: border-box;
  position: relative;
  &__container {
      max-width: 1700px;
      width: calc(100% - 200px);
      margin: 0 auto;
      // padding: 150px 0 150px 50px;
      box-sizing: border-box;
      z-index: 7;
  }
  &__container{
    display: flex;
    // justify-content: ;
  }
  &__content--left{
    width: 50%;
    max-width: 680px;
    display: flex;
    flex-direction: column;
  }
  &__content--right{
    width: 50%; 
    display: flex;
    flex-direction: column;
  }
  &__box{

  }
  &__title{
    font-family: "Montserrat",sans-serif;
    font-size: 36px;
    font-weight: 400;
    margin-bottom: 20px;
    border-radius: 10px;
    color: white;
    &--bold{
      font-weight: 800;
    }
  }
  &__text{
    font-family: "Montserrat",sans-serif;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 20px;
    border-radius: 10px;
    color: white;
  }
  &__li{
    padding-left: 20px;
    font-family: "Montserrat",sans-serif;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 20px;
    border-radius: 10px;
    color: white;
  }
}