.join-us {
  background: #0a093f;
  background-image: url('./../assets/images/JoinshapeLeft.svg'),
    url('./../assets/images/JoinshapeRight.svg');
  background-repeat: no-repeat;
  background-position: left bottom, right top;
  box-sizing: border-box;
  background-size: 70%;
  position: relative;
  box-sizing: border-box;
  padding: 15px 0 80px 0;
  @media (min-width: 910px) {
    padding: 100px;
  }
  @media (min-width: 860px) {
    background-size: 35%;
    padding: 30px 0 120px 0x;
  }
}
.join-us__inp {
  border: 3px solid white;
  border-radius: 35px;
  background: transparent;
  // max-width: 830px;
  min-width: 270px;
  margin-top: 20px;
  height: 60px;
  width: 100%;
  outline: none;
  padding: 0 20px;
  box-sizing: border-box;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  z-index: 3;
  @media (max-width: 500px){
    height: 50px;
  }
  &__container {
    width: auto;
    max-width: 830px;
    display: flex;
    justify-content: center;
    z-index: 1;
    position: relative;
    @media (min-width: 450px) {
      width: 100%;
    }
  }
}
.join-us__title {
  color: white;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  font-size: 30px;
  margin-bottom: 30px;
  margin-top: 20px;
  text-transform: uppercase;
  z-index: 2;
  @media (max-width: 750px) {
    font-size: 18px;
  }
}
.join-us__subTitle {
  font-size: 29px;
  text-align: center;
  color: white;
  font-family: 'Montserrat', sans-serif;
  @media (max-width: 750px) {
    font-size: 18px;
  }
}
.join-us__bigtitle {
  color: white;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  font-size: 36px;
  font-weight: 300;
  margin: 25px 0;
  z-index: 1;
  @media (min-width: 860px) {
    font-size: 72px;
    margin: 50px 0;
  }
}
.join-us__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15px;
  box-sizing: border-box;
  // @media(max-width: 760px){
  //   width: 70%;
  // }
  &__inp {
    position: absolute;
    background: transparent;
    border: 2px solid white;
    border-radius: 50%;
    right: 10px;
    bottom: 9px;
    cursor: pointer;
    z-index: 3;
    width: 42px;
    height: 42px;
    color: white;
    @media (max-width: 500px){
      right: 5px;
      bottom: 4px;
    }
  }
}
.work-block {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  z-index: 2;
  margin-bottom: 120px;
  &__item {
    width: 30%;
    min-width: 300px;
    margin: 20px;
    &__text {
      color: white;
      line-height: 27px;
      font-family: 'Montserrat', sans-serif;
      text-align: center;
      font-size: 18px;
      @media (max-width: 860px) {
        font-size: 16px;
      }
    }
  }
  &_img {
    width: 112px;
    height: 92px;
    object-fit: cover;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-bottom: 12px;
  }
}

.join-us__footer {
  max-width: 1370px;
  width: 100%;
  padding-top: 150px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  z-index: 2;
  &__line {
    width: 100%;
    background: white;
    border-radius: 2.5px;
    height: 5px;
  }
  &__item {
    width: 343px;
    margin: 20px;
    &__text {
      font-size: 18px;
      font-weight: 300;
      margin-top: 20px;
      @media (max-width: 800px) {
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 0;
      }
      // z-index: 1;
    }
  }
}

.particles {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.join-us__table {
  max-width: 1120px;
  width: 100%;
  z-index: 2;
  &__container {
    display: flex;
    flex-direction: column;
  }
  &__row {
    display: flex;
    // justify-content: space-between;
    // flex-wrap: wrap;
    @media (max-width: 860px) {
      flex-direction: column;
    }
    & > .join-us__table__item {
      width: 33%;
      @media (max-width: 860px) {
        width: auto;
      }
    }
    & > .join-us__table__item:nth-child(2) {
      width: 34%;
      @media (max-width: 860px) {
        width: auto;
      }
    }
  }
  &__item {
    padding: 20px;
    box-sizing: border-box;
    color: white;
    font-family: 'Montserrat', sans-serif;
    border: 1px solid white;
    background: radial-gradient(rgb(135, 21, 177) 0%, rgb(4, 0, 5) 80%);
    text-align: center;
  }
}
.join-us__select__block {
  // border: 1px solid red;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  z-index: 1;
  &__item {
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    width: 300px;
    text-align: center;
    @media (max-width: 820px) {
      font-size: 12px;
    }
    &--center {
      min-width: 245px;
      text-align: center;
      color: white;
      font-family: 'Montserrat', sans-serif;
      font-size: 24px;
      border-radius: 30px;
      padding: 12px 45px;
      box-sizing: border-box;
      background: #313280;
      @media (max-width: 1000px) {
        font-size: 16px;
        min-width: 175px;
      }
      @media (max-width: 605px) {
        font-size: 12px;
        padding: 12px 10px;
        min-width: 110px;
      }
      @media (max-width: 380px) {
        font-size: 12px;
        padding: 12px 10px;
        min-width: 80px;
      }
    }
  }
}
