.Road {
    background-image: url('./../assets/images/roadBG.png');
    // background: red;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 200px;
    padding-bottom: 150px;
    box-sizing: border-box;
    position: relative;
    &__container {
        max-width: 1700px;
        width: calc(100% - 200px);
        margin: 0 auto;
        box-sizing: border-box;
        z-index: 7;
        @media (max-width: 1200px){
            width: calc(100% - 30px);
        }
    }
    &__box{
        padding-left: 50px;
        box-sizing: border-box;
        position: relative;
        @media (max-width: 1200px){
            padding-left: 23px;
        }
        &::before{
            top: 0;
            left: 0;
            content: "";
            border-radius: 2px;
            width: 6px;
            background: white;
            height: 100%;
            position: absolute;
        }
    }
}


.RoadItem {
    background: hsla(0,0%,100%,.6);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    margin-bottom: 20px;
    padding: 20px 50px;
    border-radius: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    z-index: 3;
    @media (max-width: 800px){
        padding: 20px 30px;
    }
    &::before{
        top: 50%;
        left: -47px;
        transform: translate(-50%, -50%);
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #DDC0FF;
        position: absolute;
        &:hover{
            background: yellow;
        }
        @media (max-width: 1200px){
            left: -20px;
        }
    }
    &__content {
        padding-right: 100px;
        box-sizing: border-box;
        @media (max-width: 1200px){
            padding-right: 20px;
        }
    }
    &__title {
        font-family: "Montserrat",sans-serif;
        font-size: 36px;
        font-weight: 400;
        margin-bottom: 20px;
        border-radius: 10px;
        @media (max-width: 800px){
            font-size: 24px;
            margin-bottom: 10px;
        }
    }
    &__description {
        font-family: "Montserrat",sans-serif;
        font-size: 24px;
        @media (max-width: 800px){
            font-size: 18px;
        }
    }
    &__status {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        flex-direction: column;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -webkit-align-items: center;
        align-items: center;
    }
    &__icon-circle {
        width: 54px;
        height: 54px;
        border-radius: 50%;
        background: #0a093f;
    }
}
