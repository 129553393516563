#error-404 {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-image: url('./../assets/images/illustrationPowered.svg') , url('./../assets/images/illustrationCrosschain.svg');
  background-position: center bottom, center center;
  background-size: auto, cover;
  background-repeat: no-repeat;
  z-index: 11;
  .error-bg{
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 2;
    height: auto;
    position: absolute;
    @media (max-width: 750px) {
      right: 0;
      height: 250px;
    }
  }
  .error__title {
    font-size: 30px;
    color: white;
    font-family: 'Montserrat', sans-serif;
    @media (max-width: 750px) {
      font-size: 15px;
    }
  }
  .error-404__title {
    font-size: 300px;
    color: white;
    font-family: 'Montserrat', sans-serif;
    @media (max-width: 750px) {
      font-size: 150px;
    }
  }
  .astronaut-img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: astronaut 1.8s ease-in-out alternate-reverse infinite;
    @media (max-width: 750px) {
      width: 150px;
    }
  }
  .error-404-contnent {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 3;
  }
  .error-404__btn{
    border: 2px solid white;
    border-radius: 5px;
    padding: 12px 30px;
    color: white;
    font-family: 'Montserrat', sans-serif;
  }
}
.error-planet{
  width: 400px;
  position: absolute;
  right: 100px;
  top: 0;
  z-index: 1;
  animation: planet 2.1s ease-in-out alternate-reverse infinite;
  @media (max-width: 750px) {
    right: 15px;
  }
}
@keyframes planet {
  0% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}


@keyframes astronaut {
  0% {
    transform: translate(-60%, -50%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}